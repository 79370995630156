(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-selector-1x2/assets/javascripts/betslip-selector.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-selector-1x2/assets/javascripts/betslip-selector.js');
"use strict";

const {
  BetslipCouponType
} = svs.components.tipsen.engine.constants;
const {
  useBetslipContext
} = svs.components.tipsen.betslipComponents;
const {
  Enkelrader,
  ExternaSystemspel,
  RSystem,
  Standard,
  USystem,
  Competition
} = svs.components.tipsen.betslipSelector1x2.betslips;
const {
  Reducera
} = svs.components.tipsen.betslipComponents.betslips;
const BetslipSelector = () => {
  const {
    couponType
  } = useBetslipContext();
  let BetslipLocal;
  switch (couponType) {
    case BetslipCouponType.Standard:
      BetslipLocal = Standard;
      break;
    case BetslipCouponType.RSystem:
      BetslipLocal = RSystem;
      break;
    case BetslipCouponType.USystem:
      BetslipLocal = USystem;
      break;
    case BetslipCouponType.ReduceraFritt:
      BetslipLocal = Reducera;
      break;
    case BetslipCouponType.Enkelrader:
      BetslipLocal = Enkelrader;
      break;
    case BetslipCouponType.ExternaSystemspel:
      BetslipLocal = ExternaSystemspel;
      break;
    case BetslipCouponType.Competition:
      BetslipLocal = Competition;
      break;
    default:
      BetslipLocal = null;
      throw new Error("No betslip found for couponType: ".concat(couponType));
  }
  return React.createElement(BetslipLocal, null);
};
setGlobal('svs.components.tipsen.betslipSelector1x2.BetslipSelector', BetslipSelector);

 })(window);