(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-selector-1x2/assets/javascripts/betslips/enkelrader.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-selector-1x2/assets/javascripts/betslips/enkelrader.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  useSelector
} = ReactRedux;
const {
  format
} = svs.utils;
const {
  clx
} = svs.components.lbUtils;
const {
  Betslip,
  useBetslipContext
} = svs.components.tipsen.betslipComponents;
const {
  selectNumberOfCompletedBoards
} = svs.components.tipsen.engine.selectors.couponSelectors;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const Enkelrader = () => {
  const {
    couponId,
    isCouponValid,
    isMedium,
    price
  } = useBetslipContext();
  const completedBoards = useSelector(state => selectNumberOfCompletedBoards(state, couponId));
  const shouldDisplayBalance = isCouponValid && !isSelfService && isMedium;
  const blockName = 'pg_tipsen_betslip__content';
  const classNames = clx(blockName, {
    ["".concat(blockName, "--valid_coupon")]: isCouponValid,
    ["".concat(blockName, "--no_progress_bar")]: !isCouponValid
  });
  return React.createElement("div", {
    className: classNames
  }, React.createElement("span", {
    className: "pg_tipsen_betslip_system_info"
  }, React.createElement(Betslip.RowsView, {
    numberOfRows: completedBoards
  }, React.createElement(Betslip.BetslipLabel, null))), shouldDisplayBalance && React.createElement("span", {
    className: "pg_tipsen_betslip_balance"
  }, React.createElement(Betslip.BalanceView, null)), React.createElement("div", {
    className: "pg_tipsen_betslip_price"
  }, format.Currency(price), "\xA0KR"), isCouponValid && React.createElement("span", {
    className: "pg_tipsen_betslip_pay_button"
  }, React.createElement(Betslip.PayButton, null)));
};
setGlobal('svs.components.tipsen.betslipSelector1x2.betslips.Enkelrader', Enkelrader);

 })(window);