(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-selector-1x2/assets/javascripts/betslips/u-system.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-selector-1x2/assets/javascripts/betslips/u-system.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  useSelector
} = ReactRedux;
const {
  format
} = svs.utils;
const {
  clx
} = svs.components.lbUtils;
const {
  Betslip,
  useBetslipContext
} = svs.components.tipsen.betslipComponents;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const {
  getSystemLabelFromNumber
} = svs.components.sport.systems;
const {
  selectUSignsCount,
  selectRequiredUSigns,
  selectUSystem
} = svs.components.tipsen.engine.selectors.couponSelectors;
const USystem = () => {
  const context = useBetslipContext();
  const {
    couponId,
    isCouponValid,
    isMedium,
    price
  } = context;
  const isPlayer = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  const isAnonymous = svs.core.userSession.isAnonymous();
  const shouldDisplayBalance = isCouponValid && (isAnonymous || isPlayer) && !isSelfService && isMedium;
  const systemNum = useSelector(state => selectUSystem(state, couponId));
  const uSigns = useSelector(state => selectUSignsCount(state, couponId));
  const requiredUSigns = useSelector(state => selectRequiredUSigns(state, couponId));
  const blockName = 'pg_tipsen_betslip__content';
  const classNames = clx(blockName, {
    ["".concat(blockName, "--valid_coupon")]: isCouponValid,
    ["".concat(blockName, "--no_progress_bar")]: !isCouponValid
  });
  return React.createElement("div", {
    className: classNames
  }, React.createElement("span", {
    className: "pg_tipsen_betslip_system_info"
  }, React.createElement(Betslip.HedgesView, null), React.createElement("div", {
    className: "pg_tipsen_betslip_system"
  }, getSystemLabelFromNumber(systemNum), ' ', React.createElement("span", {
    className: "pg_tipsen_betslip_system__u_signs"
  }, uSigns), ' ', "(", requiredUSigns, ")")), shouldDisplayBalance && React.createElement("span", {
    className: "pg_tipsen_betslip_balance"
  }, React.createElement(Betslip.BalanceView, null)), !isCouponValid && React.createElement("span", {
    className: "pg_tipsen_betslip_system_instructions"
  }, "Du har fyllt i f\xF6r f\xE5 eller f\xF6r m\xE5nga alternativ"), React.createElement("div", {
    className: "pg_tipsen_betslip_price"
  }, format.Currency(price), "\xA0KR"), isCouponValid && React.createElement("span", {
    className: "pg_tipsen_betslip_pay_button"
  }, React.createElement(Betslip.PayButton, null)));
};
setGlobal('svs.components.tipsen.betslipSelector1x2.betslips.USystem', USystem);

 })(window);